<template>
    <div class="page-content">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-dark banner-content bnr">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">About Us</h1>
                    <!-- Breadcrumb row -->
                    <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li>
                                <router-link :to="{name: 'Home'}">Home</router-link>
                            </li>
                            <li>About Us</li>
                        </ul>
                    </div>
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>

        <!-- contact area -->
        <div class="content-block">
            <!-- Code Box -->
            <div class="section-full content-inner bg-white video-section">
                <div class="container">
                    <!-- Mission and Vision -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sort-title clearfix text-center">
                                <h4>Our Mission and Vision</h4>
                            </div>
                            <div class="section-content box-sort-in button-example p-b0">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 m-b30">
                                        <div class="icon-bx-wraper bx-style-2 m-l40 p-a30 left bg-gray icon-bx-ho">
                                            <div class="icon-bx-sm bg-primary m-b20"><span class="icon-cell">
                                                    <i class="fa fa-shield" style="font-size: 30px;"></i></span> </div>
                                            <div class="icon-content p-l40">
                                                <h5 class="dlab-tilte">Mission</h5>
                                                <p>
                                                    Citadel British International school (CIS) will provide a
                                                    comprehensive and
                                                    challenging education that fosters informed and engaged local,
                                                    regional and global citizenship.
                                                    We inspire students to be lifelong learners who contribute
                                                    positively to a diverse and changing world.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 m-b30">
                                        <div class="icon-bx-wraper bx-style-2 m-l40 p-a30 left bg-gray icon-bx-ho">
                                            <div class="icon-bx-sm bg-primary m-b20"><a href="javascript:void(0);"
                                                    class="icon-cell">
                                                    <i class="fa fa-shield" style="font-size: 30px;"></i></a> </div>
                                            <div class="icon-content p-l40">
                                                <h5 class="dlab-tilte">Vision</h5>
                                                <p>
                                                    Preparing a creative, cooperative generation that is ethically and
                                                    scientifically committed, keeping pace with development and
                                                    interacting with
                                                    its society in light of National standards for education.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Values -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sort-title clearfix text-center">
                                <h4>Our Values</h4>
                                <p style="margin: 10px 0 0 0;">At CIS, we promote values that combine the
                                    pillars of character education with our cultural and spiritual essence.
                                    We encourage and recognize the following values:</p>
                            </div>
                            <div class="section-content box-sort-in m-b10 p-b0 button-example m-b30 ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <ul class="list-star red list-box">
                                            <li>
                                                Innovation <br>
                                                <small> Citadel School Students are encouraged to research,
                                                    explore, and use all the tools to uncover something new.
                                                </small>
                                            </li>
                                            <li>
                                                Honesty <br>
                                                <small>Citadel School Students have strong moral principles
                                                    that guide their actions and relations.</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="list-star red list-box">
                                            <li>
                                                Critical thinking <br>
                                                <small>Citadel School Students use a higher level of
                                                    thinking to solve complex problems.</small>
                                            </li>
                                            <li>
                                                Citizenship <br>
                                                <small>Citadel School Students are supportive to others,
                                                    serve the community and are responsible world
                                                    citizens.</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="list-star red list-box">
                                            <li>
                                                Respect <br>
                                                <small>Citadel School Students are polite and respectful to
                                                    everyone on campus and follow the directions of all
                                                    school officials.</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .bnr {
        background-image: url("~@/assets/imgs/bnr1.jpg") !important;
    }
</style>